import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { compose, createStore, applyMiddleware } from 'redux';
import App from './App';
import reducers from './store/reducers';
import { requestMiddleware } from './helpers/redux-request';

import reportWebVitals from './reportWebVitals';

Modal.setAppElement(document.body);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(requestMiddleware)),
);
window.store = store;

requestMiddleware.on.fail = ((err) => err.response || err);

ReactDOM.render((
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
), document.getElementById('root'));

reportWebVitals();
