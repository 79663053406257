import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_USER_PROFILE = define('GET_USER_PROFILE');

export function getUserProfileRequest(data) {
  return GET_USER_PROFILE.request(() => Api.getUserProfile(data));
}

export const USER_LOGIN = define('USER_LOGIN');

export function loginRequest(data) {
  return USER_LOGIN.request(() => Api.login(data));
}

export const SET_TOKEN = define('SET_TOKEN');

export function setTokenRequest(token) {
  return {
    type: SET_TOKEN,
    payload: { token },
  };
}

export const GET_USER_PROFILE_BY_ID = define('GET_USER_PROFILE_BY_ID');

export function getUserProfileByIdRequest(id) {
  return GET_USER_PROFILE_BY_ID.request(() => Api.getUserProfileById(id));
}

export const USER_PROFILE_UPDATE = define('USER_PROFILE_UPDATE');

export function userProfileUpdateRequest(data) {
  return USER_PROFILE_UPDATE.request(() => Api.uploadProfile(data));
}

export const CREATE_SHIPPER_REQUEST = define('CREATE_SHIPPER_REQUEST');

export function createShipperRequest(data) {
  return CREATE_SHIPPER_REQUEST.request(() => Api.createShipper(data));
}

export const CREATE_SHIPPER_REQUEST_TWO = define('CREATE_SHIPPER_REQUEST_TWO');

export function createShipperRequestTwo(data) {
  return CREATE_SHIPPER_REQUEST_TWO.request(() => Api.createShipperTwo(data));
}

export const DELETE_ERROR_BY_KEY = 'DELETE_ERROR_BY_KEY';

export function deleteErrorByKey(key) {
  return {
    type: DELETE_ERROR_BY_KEY,
    payload: { key },
  };
}

export const USER_PROFILE_CONFIRM = define('USER_PROFILE_CONFIRM');

export function userProfileConfirmRequest(id) {
  return USER_PROFILE_CONFIRM.request(() => Api.userProfileConfirm(id));
}

export const SET_ACCOUNT_FORM_DATA = 'SET_ACCOUNT_FORM_DATA';

export function setAccountFormData(formData) {
  return {
    type: SET_ACCOUNT_FORM_DATA,
    payload: { formData },
  };
}

export const CHANGE_ACCOUNT_DATA = 'CHANGE_ACCOUNT_DATA';

export function changeAccountData(key, value) {
  return {
    type: CHANGE_ACCOUNT_DATA,
    payload: { key, value },
  };
}

export const DELETE_USER = define('DELETE_USER');

export function deleteUserRequest(id) {
  return DELETE_USER.request(() => Api.deleteUser(id));
}

export const CONNECT_TO_DAT = define('CONNECT_TO_DAT');

export function connectToDatRequest(data, type) {
  return CONNECT_TO_DAT.request(() => Api.connectToDat(data, type));
}

export const DISCONNECT_FROM_DAT = define('DISCONNECT_FROM_DAT');

export function disconnectFromDatRequest(type) {
  return DISCONNECT_FROM_DAT.request(() => Api.disconnectFromDat(type));
}
