const { REACT_APP_MODE } = process.env;
const config = {
  dev: {
    API_URL: 'https://api.kondllc.com',
  },
  prod: {
    API_URL: 'https://api-v1.zerobroker.com',
  },
  loc: {
    API_URL: 'http://localhost:4000',
  },
  staging: {
    API_URL: 'https://api-staging.zerobroker.com',
  },
  kond: {
    API_URL: 'https://api.kondllc.com',
  },
};

module.exports = config[REACT_APP_MODE] || config.prod;
