import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_CARRIERS_ALL_STATUSES_LIST = define('GET_CARRIERS_ALL_STATUSES_LIST');

export function getCarriersListAllStatusesRequest(s) {
  return GET_CARRIERS_ALL_STATUSES_LIST.request(() => Api.getCarriersAllStatuses(s)).takeLatest();
}

export const GET_CARRIERS_LIST_BY_STATUS = define('GET_CARRIERS_LIST_BY_STATUS');

export function getCarriersListByStatusRequest(status, page, s, filter) {
  return GET_CARRIERS_LIST_BY_STATUS.request(() => Api.getCarriersByStatus(status, page, s, filter)).takeLatest();
}

export const CREATE_CARRIER = define('CREATE_CARRIER');

export function createCarrierRequest(data) {
  return CREATE_CARRIER.request(() => Api.createCarrier(data));
}

export const GET_CARRIER_BY_ID = define('GET_CARRIER_BY_ID');

export function getCarrierByIdRequest(id) {
  return GET_CARRIER_BY_ID.request(() => Api.getCarrierById(id));
}
