import _ from 'lodash';
import moment from 'moment';
import {
  GET_PLACE_BY_ID,
  SHIPMENT_ESTIMATION,
  CREATE_SHIPMENT,
  GET_SHIPMENTS,
  GET_SHIPMENT_BY_ID,
  CREATE_SHIPMENT_REQUEST_DATA_STEP_ONE,
  CREATE_SHIPMENT_REQUEST_DATA_STEP_TWO,
  CREATE_SHIPMENT_REQUEST_DATA_STEP_THREE,
  LOAD_ASSIGN,
  GET_USERS_COMPANIES,
  CREATE_SHIPMENT_REQUEST_DATA,
  REMOVE_SHIPMENT_REQUEST_DATA,
  GET_SHIPPERS_LIST,
  GET_ROUTE_DATA, GET_NEW_SMS_NO, GET_DATA,
} from '../actions/shipments';

const initShipmentRequestDataStepOne = {
  equipment: '',
  dropOffLocation: '',
  pickUpLocation: '',
  dropOffDate: '',
  pickUpDate: '',
  noCalendar: true,
};

const initShipmentRequestDataStepTwo = {
  pickUpFacilityName: '',
  pickUpFirstComeFirstServer: '',
  pickUpAppointmentStartDate: '',
  pickUpAppointmentEndDate: '',
  pickUpNumber: '',
  pickUpContactInfo: { phone: '' },
  dropOffFacilityName: '',
  dropOffAppointmentStartDate: '',
  dropOffAppointmentEndDate: '',
  dropOffFirstComeFirstServer: '',
  dropOffContactInfo: { phone: '' },
  dropOffNumber: '',
};

const initShipmentRequestDataStepThree = {
  customerReferenceNumber: '',
  commodity: '',
  weight: '',
  qty: '',
  loadNote: '',
  preCoolTemperature: '',
  minTrailerTemperature: '',
  maxTrailerTemperature: '',
  operatingInstructions: '',
  trailerWashout: false,
  packageType: '',
};

const initialState = {
  placesData: {},
  shipmentEstimation: {},
  shipmentEstimationStatus: '',
  shipment: {},
  shipmentStatus: '',
  shipmentsList: {},
  shipmentsCount: {},
  shipmentsListStatus: '',
  estimationRequestData: {},
  companies: [],
  shippersList: [],
  shippersListStatus: '',
  routeData: {},
  routeDataStatus: '',
  shipmentRefreshStatus: false,
  shipmentRequestDataStepOne: initShipmentRequestDataStepOne,
  shipmentRequestDataStepTwo: initShipmentRequestDataStepTwo,
  shipmentRequestDataStepThree: initShipmentRequestDataStepThree,
  canNotifyShipments: [],
  comodityData: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_ASSIGN.REQUEST: {
      return {
        ...state,
      };
    }
    case LOAD_ASSIGN.SUCCESS: {
      return {
        ...state,
      };
    }
    case LOAD_ASSIGN.FAIL: {
      return {
        ...state,
      };
    }
    case GET_ROUTE_DATA.REQUEST: {
      return {
        ...state,
        routeDataStatus: 'request',
        routeData: {},
      };
    }
    case GET_ROUTE_DATA.SUCCESS: {
      return {
        ...state,
        routeDataStatus: 'ok',
        routeData: action.payload.data,
      };
    }
    case GET_ROUTE_DATA.FAIL: {
      return {
        ...state,
        routeDataStatus: 'fail',
        routeData: {},
      };
    } case GET_DATA.REQUEST: {
      return {
        ...state,
        comodityData: {},
      };
    }
    case GET_DATA.SUCCESS: {
      return {
        ...state,
        comodityData: action.payload.data.data,
      };
    }
    case GET_DATA.FAIL: {
      return {
        ...state,
        comodityData: {},
      };
    }
    case GET_USERS_COMPANIES.REQUEST: {
      return {
        ...state,
      };
    }
    case GET_USERS_COMPANIES.SUCCESS: {
      return {
        ...state,
        companies: action.payload.data.companies,
      };
    }
    case CREATE_SHIPMENT_REQUEST_DATA: {
      const { step, key, value } = action.payload;
      const shipmentRequestData = {
        ...state.shipmentRequestData,
        [step]: {
          ...state.shipmentRequestData[step],
          [key]: value,
        },
      };
      return {
        ...state,
        shipmentRequestData,
      };
    }
    case CREATE_SHIPMENT_REQUEST_DATA_STEP_ONE: {
      const { key, value } = action.payload;
      const shipmentRequestDataStepOne = { ...state.shipmentRequestDataStepOne };
      _.set(shipmentRequestDataStepOne, key, value);
      return {
        ...state,
        shipmentRequestDataStepOne,
      };
    }
    case CREATE_SHIPMENT_REQUEST_DATA_STEP_TWO: {
      const { key, value } = action.payload;
      const shipmentRequestDataStepTwo = { ...state.shipmentRequestDataStepTwo };
      _.set(shipmentRequestDataStepTwo, key, value);
      return {
        ...state,
        shipmentRequestDataStepTwo,
      };
    }
    case CREATE_SHIPMENT_REQUEST_DATA_STEP_THREE: {
      const { key, value } = action.payload;
      const shipmentRequestDataStepThree = { ...state.shipmentRequestDataStepThree };
      _.set(shipmentRequestDataStepThree, key, value);
      return {
        ...state,
        shipmentRequestDataStepThree,
      };
    }
    case GET_PLACE_BY_ID.SUCCESS: {
      const { key, data: { data: { place } } } = action.payload;
      const placesData = { ...state.placesData, [key]: place };
      return {
        ...state,
        placesData,
      };
    }
    case SHIPMENT_ESTIMATION.REQUEST: {
      return {
        ...state,
        shipmentEstimationStatus: 'request',
      };
    }
    case SHIPMENT_ESTIMATION.SUCCESS: {
      const { data, requestData, type } = action.payload;
      const estimationRequestData = { ...state.estimationRequestData };
      const shipmentRequestDataStepOne = { ...state.shipmentRequestDataStepOne };
      if (type === 'create' && (estimationRequestData.origin !== data.origin.place.place_id
        || estimationRequestData.destination !== data.destination.place.place_id)) {
        shipmentRequestDataStepOne.dropOffDate = '';
        shipmentRequestDataStepOne.pickUpDate = '';
      }
      return {
        ...state,
        shipmentEstimation: data,
        shipmentEstimationStatus: 'ok',
        estimationRequestData: requestData,
        shipmentRequestDataStepOne,
      };
    }
    case SHIPMENT_ESTIMATION.FAIL: {
      return {
        ...state,
        shipmentEstimationStatus: 'fail',
        shipmentEstimation: {},
      };
    }
    case CREATE_SHIPMENT.SUCCESS: {
      return {
        ...state,
        shipmentRequestDataStepOne: initShipmentRequestDataStepOne,
        shipmentRequestDataStepTwo: {
          initShipmentRequestDataStepTwo,
          pickUpContactInfo: { phone: '' },
          dropOffContactInfo: { phone: '' },
        },
        shipmentRequestDataStepThree: initShipmentRequestDataStepThree,
        shipmentEstimation: {},
        placesData: {},
        routeData: {},
      };
    }
    case CREATE_SHIPMENT.FAIL: {
      return {
        ...state,
        shipmentRequestDataStepOne: initShipmentRequestDataStepOne,
        shipmentRequestDataStepTwo: {
          initShipmentRequestDataStepTwo,
          pickUpContactInfo: { phone: '' },
          dropOffContactInfo: { phone: '' },
        },
        shipmentRequestDataStepThree: initShipmentRequestDataStepThree,
        shipmentEstimation: {},
        placesData: {},
        routeData: {},
      };
    }
    case GET_SHIPMENTS.REQUEST: {
      return {
        ...state,
        shipmentsListStatus: 'request',
        shipmentRefreshStatus: action.payload.refresh,
      };
    }
    case GET_SHIPMENTS.SUCCESS: {
      const { data, status } = action.payload;
      const shipmentsList = { ...state.shipmentsList };
      let shipmentsCount = { ...state.shipmentsCount };
      shipmentsCount = data.counts;
      if (!status) {
        shipmentsList.all = data;
      } else {
        shipmentsList[status] = data;
      }
      return {
        ...state,
        shipmentsList,
        shipmentsListStatus: 'ok',
        shipmentsCount,
        shipmentRefreshStatus: true,
      };
    }
    case GET_SHIPMENTS.FAIL: {
      return {
        ...state,
        shipmentsListStatus: 'fail',
        shipmentsList: {},
      };
    }
    case GET_SHIPMENT_BY_ID.REQUEST: {
      return {
        ...state,
        shipmentStatus: 'request',
      };
    }
    case GET_SHIPMENT_BY_ID.SUCCESS: {
      const {
        load, load: {
          equipment, dropOff, pickUp, _id, equipmentDetails, customerReferenceNumber, optionTruckStop,
          commodityTruckStop, weight, qty, loadNote, packageType, total, carrierInformation, commodity,
        },
      } = action.payload.data;
      let shipmentRequestDataStepOne = { ...state.shipmentRequestDataStepOne };
      let shipmentRequestDataStepTwo = { ...state.shipmentRequestDataStepTwo };
      let shipmentRequestDataStepThree = { ...state.shipmentRequestDataStepThree };
      shipmentRequestDataStepOne = {
        id: _id,
        equipment,
        dropOffLocation: dropOff.placeInfo?.place_id,
        pickUpLocation: pickUp.placeInfo?.place_id,
        dropOffDate: moment.utc(dropOff.date).format('YYYY-MM-DD'),
        pickUpDate: moment.utc(pickUp.date).format('YYYY-MM-DD'),
        currentPickUpLocation: pickUp.address,
        currentDropOffLocation: dropOff.address,
        price: total,
        carrierInformation,
      };
      shipmentRequestDataStepTwo = {
        pickUpFacilityName: pickUp.facilityName,
        pickUpFirstComeFirstServer: pickUp.firstComeFirstServer ? 1 : 0,
        pickUpAppointmentStartDate: moment.utc(pickUp?.appointmentStartDate).format('h:mm A'),
        pickUpAppointmentEndDate: moment.utc(pickUp?.appointmentEndDate).format('h:mm A'),
        pickUpNumber: pickUp.number,
        pickUpContactInfo: pickUp.contactInfo,
        dropOffFacilityName: dropOff.facilityName,
        dropOffAppointmentStartDate: moment.utc(dropOff?.appointmentStartDate).format('h:mm A'),
        dropOffAppointmentEndDate: moment.utc(dropOff?.appointmentEndDate).format('h:mm A'),
        dropOffFirstComeFirstServer: dropOff.firstComeFirstServer ? 1 : 0,
        dropOffContactInfo: dropOff.contactInfo,
        dropOffNumber: dropOff.number,
      };
      shipmentRequestDataStepThree = {
        customerReferenceNumber,
        commodityTruckStop,
        commodity,
        optionTruckStop,
        weight,
        qty,
        loadNote,
        preCoolTemperature: equipmentDetails.preCoolTemperature,
        minTrailerTemperature: equipmentDetails.minTrailerTemperature,
        maxTrailerTemperature: equipmentDetails.maxTrailerTemperature,
        operatingInstructions: equipmentDetails.operatingInstructions,
        trailerWashout: equipmentDetails.trailerWashout,
        packageType,
      };
      return {
        ...state,
        shipment: load,
        shipmentStatus: 'ok',
        shipmentRequestDataStepOne,
        shipmentRequestDataStepTwo,
        shipmentRequestDataStepThree,
      };
    }
    case GET_SHIPMENT_BY_ID.FAIL: {
      return {
        ...state,
        shipmentStatus: 'fail',
      };
    }
    case REMOVE_SHIPMENT_REQUEST_DATA: {
      return {
        ...state,
        shipmentRequestDataStepOne: initShipmentRequestDataStepOne,
        shipmentRequestDataStepTwo: {
          initShipmentRequestDataStepTwo,
          pickUpContactInfo: { phone: '' },
          dropOffContactInfo: { phone: '' },
        },
        shipmentRequestDataStepThree: initShipmentRequestDataStepThree,
        shipmentEstimation: {},
        placesData: {},
        estimationRequestData: {},
        routeData: {},
      };
    }
    case GET_SHIPPERS_LIST.REQUEST: {
      return {
        ...state,
        shippersListStatus: 'request',
      };
    }
    case GET_SHIPPERS_LIST.SUCCESS: {
      return {
        ...state,
        shippersListStatus: 'ok',
        shippersList: action.payload.data.companies,
      };
    }
    case GET_SHIPPERS_LIST.FAIL: {
      return {
        ...state,
        shippersListStatus: 'fail',
        shippersList: [],
      };
    }
    case GET_NEW_SMS_NO.SUCCESS: {
      const { shipmentId, data: { canNotify } } = action.payload;
      const canNotifyShipments = [...state.canNotifyShipments];
      const i = canNotifyShipments.findIndex((c) => c._id === shipmentId);
      if (i > -1) {
        canNotifyShipments[i] = {
          _id: shipmentId,
          canNotify,
        };
      } else {
        canNotifyShipments.push({
          _id: shipmentId,
          canNotify,
        });
      }
      return {
        ...state,
        canNotifyShipments,
      };
    }
    default: {
      return state;
    }
  }
}
