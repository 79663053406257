class Account {
  static set(data) {
    localStorage.setItem('account', JSON.stringify(data));
  }

  static setToken(token) {
    localStorage.setItem('token', token);
  }

  static get() {
    try {
      const account = localStorage.getItem('account') || sessionStorage.getItem('account');
      return JSON.parse(account) || {};
    } catch (e) {
      return {};
    }
  }

  static add(data) {
    this.set({ ...this.get(), ...data });
  }

  static getToken() {
    return localStorage.getItem('token') || sessionStorage.getItem('token') || '';
  }

  static delete() {
    localStorage.removeItem('token');
    localStorage.removeItem('account');

    sessionStorage.removeItem('token');
    sessionStorage.removeItem('account');
  }
}

export default Account;
