import axios from 'axios';
import { stringify as qs } from 'query-string';
import { serialize } from 'object-to-formdata';
import Account from './helpers/Account';
import config from './config';

const apiUrl = config.API_URL;

const api = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

// eslint-disable-next-line no-shadow
api.interceptors.request.use((config) => {
  const token = Account.getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => Promise.reject(error));

api.interceptors.response.use((res) => res, (error) => {
  if (error?.response?.status === 403) {
    Account.delete();
    window.location.href = '/';
  }
  return Promise.reject(error);
});

const apiTokens = {};

function toFormData(data) {
  return serialize({ ...data }, { indices: false });
}

class Api {
  static cancel(methodName) {
    if (apiTokens[methodName]) {
      apiTokens[methodName]();
    }
  }

  static cancelToken(methodName) {
    return new axios.CancelToken((token) => {
      apiTokens[methodName] = token;
    });
  }

  static signUpStepOne(data) {
    return api.post('/users/register/shipper/step-one', data);
  }

  static signUpStepTwo(data) {
    return api.post('/users/register/shipper/step-two', data);
  }

  static signUpStepThree(data) {
    return api.post('/users/register/shipper/step-three', data);
  }

  static forgotPassword(data) {
    return api.post('/users/forgot/password', data);
  }

  static setPassword(data) {
    return api.put('/users/forgot/password', data);
  }

  static getUserProfile() {
    return api.get('/users/profile');
  }

  static getUserProfileById(id) {
    return api.get(`/users/profile?userId=${id}`);
  }

  static login(data) {
    return api.post('/users/login', data);
  }

  static googleAutocomplete(s) {
    const query = qs(s);
    return api.get(`/places/autocomplete?${query}`);
  }

  static getPlaceById(id) {
    const query = qs({ id });
    return api.get(`/places/get?${query}`);
  }

  static getRouteData(data) {
    return api.get('/places/get/route', { params: { ...data } });
  }

  static shipmentEstimation(data) {
    const query = qs(data);
    return api.get(`/places/estimate?${query}`);
  }

  static resendEmail(data) {
    return api.post('/users/register/shipper/resend', data);
  }

  static createShipment(data) {
    return api.post('/loads/create', data);
  }

  static getShipments(status, limit, userType, id, selectedUserType, s) {
    this.cancel('getShipments');
    return api.get(`/loads/${userType}`, {
      cancelToken: this.cancelToken('getShipments'),
      params: {
        status, limit, [`${selectedUserType}Id`]: id, s,
      },
    });
  }

  static cancelShipment(id) {
    return api.get(`/loads/cancel/${id}`);
  }

  static getShipmentById(id) {
    return api.get(`/loads/single/${id}`);
  }

  static getUserCompanies(s) {
    this.cancel('getUserCompanies');
    return api.get('/users/companies', { params: { s }, cancelToken: this.cancelToken('getUserCompanies') });
  }

  static uploadShipmentDocument(data) {
    return api.post('/loads/upload/document', toFormData(data));
  }

  static deleteShipmentDocument(id) {
    return api.delete('/loads/delete/document', { data: { id } });
  }

  static loadsAssign(data) {
    return api.post('/loads/assign', data);
  }

  static searchMc(s, trailerType) {
    return api.get('/users/carriers', { params: { s, trailerType } });
  }

  static updateShipment(data) {
    return api.put('/loads/update', data);
  }

  static updateStatus(data) {
    return api.put('/loads/update/status', data);
  }

  static uploadProfile(data) {
    return api.put('/users/profile/update', toFormData(data));
  }

  static updatePaymentStatus(data) {
    return api.put('/loads/update/payment/status', data);
  }

  static createShipper(data) {
    return api.post('/users/create/shipper/step-one', data);
  }

  static createShipperTwo(data) {
    return api.post('/users/create/shipper/step-two', data);
  }

  static getShippersList(s) {
    return api.get('/users/shippers', { params: { s } });
  }

  static addManager(data) {
    return api.post('/users/create/manager/step-one', data);
  }

  static setManagerPassword(data) {
    return api.post('/users/create/manager/step-two', data);
  }

  static assignPhone(data) {
    return api.post('loads/assign/phone', data);
  }

  static userProfileConfirm(id) {
    return api.get('/users/profile/confirm', { params: { id } });
  }

  static userLoginAfterConfirmation() {
    return api.get('/users/logged/in');
  }

  static deleteShipment(id) {
    return api.delete('/loads/delete', { data: { id } });
  }

  static uploadXLSXFile(data) {
    return api.post('/data-templates/import/xlsx', toFormData(data));
  }

  static uploadCSVFile(data) {
    return api.post('/carriers-list/import/csv', toFormData(data));
  }

  static getCarriersList(page) {
    return api.get('/carriers-list', { params: { page } });
  }

  static getNewSms(id) {
    return api.get('/carriers-list/notify', { params: { id }, timeout: 120000 });
  }

  static getNewSmsNo(id) {
    return api.get('/carriers-list/can-notify-more', { params: { id } });
  }

  static deleteUser(id) {
    return api.delete(`/users/delete/${id}`);
  }

  static getCarriersAllStatuses(s) {
    this.cancel('getCarriersALlStatuses');
    return api.get('/carriers-list/all', { params: { s }, cancelToken: this.cancelToken('getCarriersALlStatuses') });
  }

  static getCarriersByStatus(status, page = 1, s, filter) {
    this.cancel('getCarriersByStatus');
    return api.get('/carriers-list/by-status', {
      params: {
        status, page, s, filter,
      },
      cancelToken: this.cancelToken('getCarriersByStatus'),
    });
  }

  static createCarrier(data) {
    return api.post('/users/create/carrier', data);
  }

  static getCarrierById(id) {
    return api.get('/carriers-list/carrier', { params: { id } });
  }

  static connectToDat(data, type) {
    return api.put(`/users/profile/${type}/update`, data);
  }

  static disconnectFromDat(type) {
    return api.delete(`/users/profile/${type}/delete`);
  }

  static postShipment(id) {
    return api.get(`/loads/post/${id}`);
  }

  static unpostShipment(id) {
    return api.delete(`/loads/post/${id}`);
  }

  static getData() {
    return api.get('/places/truckstop/data');
  }
}

export default Api;
