import { ADD_MANAGER } from '../actions/manager';

const initialState = {
  addManagerStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_MANAGER.SUCCESS: {
      return {
        ...state,
        addManagerStatus: 'ok',
      };
    }
    default: {
      return state;
    }
  }
}
