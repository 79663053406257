import { combineReducers } from 'redux';
import signUp from './signUp';
import account from './account';
import shipments from './shipments';
import manager from './manager';
import carriers from './carriers';

export default combineReducers({
  account,
  signUp,
  shipments,
  manager,
  carriers,
});
