import {
  GET_CARRIERS_ALL_STATUSES_LIST, GET_CARRIERS_LIST_BY_STATUS, CREATE_CARRIER, GET_CARRIER_BY_ID,
} from '../actions/carriers';

const initialState = {
  carriersListAllStatuses: {},
  carriersListAllStatusesStatus: '',
  carriersListByStatus: {},
  carriersListByStatusStatus: '',
  createCarrierStatus: '',
  carrierData: '',
  carrierDataStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CARRIERS_ALL_STATUSES_LIST.REQUEST: {
      return {
        ...state,
        carriersListAllStatusesStatus: 'request',
      };
    }
    case GET_CARRIERS_ALL_STATUSES_LIST.SUCCESS: {
      return {
        ...state,
        carriersListAllStatusesStatus: 'ok',
        carriersListAllStatuses: action.payload.data,
      };
    }
    case GET_CARRIERS_ALL_STATUSES_LIST.FAIL: {
      return {
        ...state,
        carriersListAllStatusesStatus: 'fail',
      };
    }
    case GET_CARRIERS_LIST_BY_STATUS.REQUEST: {
      return {
        ...state,
        carriersListByStatusStatus: 'request',
      };
    }
    case GET_CARRIERS_LIST_BY_STATUS.SUCCESS: {
      return {
        ...state,
        carriersListByStatusStatus: 'ok',
        carriersListByStatus: action.payload,
      };
    }
    case GET_CARRIERS_LIST_BY_STATUS.FAIL: {
      return {
        ...state,
        carriersListByStatusStatus: 'fail',
      };
    }
    case CREATE_CARRIER.REQUEST: {
      return {
        ...state,
        createCarrierStatus: 'request',
      };
    }
    case CREATE_CARRIER.SUCCESS: {
      return {
        ...state,
        createCarrierStatus: 'ok',
      };
    }
    case CREATE_CARRIER.FAIL: {
      return {
        ...state,
        createCarrierStatus: 'fail',
      };
    }
    case GET_CARRIER_BY_ID.REQUEST: {
      return {
        ...state,
        carrierDataStatus: 'request',
      };
    }
    case GET_CARRIER_BY_ID.SUCCESS: {
      return {
        ...state,
        carrierDataStatus: 'ok',
        carrierData: action.payload.data.carrier,
      };
    }
    case GET_CARRIER_BY_ID.FAIL: {
      return {
        ...state,
        carrierDataStatus: 'fail',
        carrierData: {},
      };
    }
    default: {
      return state;
    }
  }
}
