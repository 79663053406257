import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const ADD_MANAGER = define('ADD_MANAGER');

export function addManagerRequest(data) {
  return ADD_MANAGER.request(() => Api.addManager(data));
}

export const SET_MANAGER_PASSWORD = define('SET_MANAGER_PASSWORD');

export function setManagerPasswordRequest(data) {
  return SET_MANAGER_PASSWORD.request(() => Api.setManagerPassword(data));
}
