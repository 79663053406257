import React, { Component, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

const AppBook = React.lazy(() => import('./pages/AppBook'));
const Navigation = React.lazy(() => import('./Navigation'));

const suspense = (Comp) => (props) => (
  <Suspense fallback={<div className="loading" />}>
    <Comp {...props} />
  </Suspense>
);

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/app" exact component={suspense(AppBook)} />
          <Route path="/book/:loadId" exact component={suspense(AppBook)} />
          <Route component={suspense(Navigation)} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
