import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const SIGN_UP_STEP_ONE = define('SIGN_UP_STEP_ONE');

export function createShipmentStepOneRequest(data) {
  return SIGN_UP_STEP_ONE.request(() => Api.signUpStepOne(data));
}

export const SIGN_UP_STEP_TWO = define('SIGN_UP_STEP_TWO');

export function createShipmentStepTwoRequest(data) {
  return SIGN_UP_STEP_TWO.request(() => Api.signUpStepTwo(data));
}

export const SIGN_UP_STEP_THREE = define('SIGN_UP_STEP_THREE');

export function createShipmentStepThreeRequest(data) {
  return SIGN_UP_STEP_THREE.request(() => Api.signUpStepThree(data));
}

export const SIGN_UP_REQUEST_DATA = 'SIGN_UP_REQUEST_DATA';

export function signUpRequestData(key, value, step) {
  return {
    type: SIGN_UP_REQUEST_DATA,
    payload: { key, value, step },
  };
}

export const FORGOT_PASSWORD = define('FORGOT_PASSWORD');

export function forgotPasswordRequest(data) {
  return FORGOT_PASSWORD.request(() => Api.forgotPassword(data));
}

export const SET_PASSWORD = define('SET_PASSWORD');

export function setPasswordRequest(data) {
  return SET_PASSWORD.request(() => Api.setPassword(data));
}

export const RESEND_EMAIL = define('RESEND_EMAIL');

export function resendEmailRequest(data) {
  return RESEND_EMAIL.request(() => Api.resendEmail(data));
}

export const RESET_SIGN_UP_REQUEST_DATA = 'RESET_SIGN_UP_REQUEST_DATA';

export function resetSignUpRequestData() {
  return {
    type: RESET_SIGN_UP_REQUEST_DATA,
    payload: {},
  };
}
